import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'mobx-react';
import { Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Axios from 'axios';

import RootStore from '../shared/store';
import App from '../shared/App';
import IntlProvider from '../shared/i18n/IntlProvider';
import createHistory from '../shared/store/history';

Axios.defaults.baseURL = process.env.API_URL || 'https://api.chantal.combat.nl';

const history = createHistory();

const store = window.store || new RootStore(JSON.parse(window.__PRELOADED_STATE__));

hydrate(
    <Provider {...store.stores}>
        <Router history={history}>
            <IntlProvider>
                <HelmetProvider>
                    <App />
                </HelmetProvider>
            </IntlProvider>
        </Router>
    </Provider>,
    document.getElementById('app')
);

if (process.env.NODE_ENV === 'development') {
    if (module.hot) {
        module.hot.accept();
    }

    if (!window.store) {
        window.store = store;
    }
}
