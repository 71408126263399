import { observable, action } from 'mobx';
import Axios from 'axios';
import { BaseStore } from '../base-store';

export enum FetchStatus {
    PENDING,
    SUCCESS,
    ERROR,
}

export interface GridCell {
    id: number;
    path: string;
    title: string;
    image: string;
}

export type GridResponseDto = GridCell[];

export class GridStore extends BaseStore<GridStore> {
    @observable status: FetchStatus = FetchStatus.PENDING;
    @observable gridCells: GridCell[] = [];

    @action setStatus = (status: FetchStatus) => {
        this.status = status;
    };

    @action setGridCells = (gridCells: GridCell[]) => {
        this.gridCells = gridCells;
    };

    @action fetchGridCells = async () => {
        this.setStatus(FetchStatus.PENDING);
        try {
            const res = await Axios.get<GridResponseDto>('/combat-react/api/grid');
            this.setGridCells(res.data);
            this.setStatus(FetchStatus.SUCCESS);
        } catch (err) {
            this.setGridCells([]);
            this.setStatus(FetchStatus.ERROR);
            console.error(err.message);
        }
    };
}
