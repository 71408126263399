import React, { useRef, useEffect, useState } from 'react';
import videojs, { VideoJsPlayer } from 'video.js';

interface Source {
    src: string;
    type: string;
}

interface Props {
    autoplay?: boolean;
    controls?: boolean;
    poster?: string;
    sources: Source[];
}

const usePlayer = ({ sources, autoplay, controls, poster }: Props) => {
    const options: videojs.PlayerOptions = {
        fluid: true,
        preload: 'auto',
        html5: {
            hls: {
                enableLowInitialPlaylist: true,
                smoothQualityChange: true,
                overrideNative: true,
            },
        },
    };

    const videoRef = useRef<HTMLVideoElement>(null);
    const [player, setPlayer] = useState<VideoJsPlayer>();

    useEffect(() => {
        const vjsPlayer = videojs(videoRef.current, {
            ...options,
            controls,
            autoplay,
            poster,
            sources,
        });
        setPlayer(vjsPlayer);

        return () => {
            if (player) {
                player.dispose();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sources]);

    useEffect(() => {
        if (player) {
            player.src(sources);
        }
    }, [player, sources]);

    return videoRef;
};
const VideoPlayer = ({ sources, controls, autoplay, poster }: Props) => {
    const playerRef = usePlayer({ sources, controls, autoplay, poster });

    return (
        <div data-vjs-player>
            <video ref={playerRef} className="video-js" />
        </div>
    );
};

export default VideoPlayer;
