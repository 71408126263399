import React from 'react';

import useStores from '../../store/use-stores';
import PimcoreImage from '../PimcoreImage';

const ContentPaneImage = () => {
    const { PageStore } = useStores();

    return (
        <div className="headerImage">
            <PimcoreImage
                name="headerImage"
                required={true}
                thumbnail="headerImage"
                className="headerImage"
                data={PageStore.elements.headerImage?.data as any}
            />
        </div>
    );
};

export default ContentPaneImage;
