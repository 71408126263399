type FunctionComp = (props: any) => JSX.Element;

interface Brick {
    name: string;
    component: FunctionComp;
}

export default class BrickRegistry {
    static bricks: { [key: string]: FunctionComp } = {};

    static register(brick: Brick) {
        BrickRegistry.bricks[`${brick.name}`] = brick.component;
    }

    static get(name: string) {
        if (BrickRegistry.bricks[`${name}`]) {
            return BrickRegistry.bricks[`${name}`];
        }
    }

    static hydrate(bricks: string) {
        BrickRegistry.bricks = JSON.parse(bricks);
    }

    static serialize() {
        return JSON.stringify(BrickRegistry.bricks);
    }
}
