import React from 'react';

import Alert from 'react-bootstrap/Alert';
import HtmlHolder from '../HtmlHolder';
import PimcoreVideoYoutube from './PimcoreVideoYoutube';
import PimcoreVideoVimeo from './PimcoreVideoVimeo';
import PimcoreVideoAsset from './PimcoreVideoAsset';

export interface BaseVideoProps {
    id: string | number;
    type: 'asset' | 'youtube' | 'vimeo' | 'dailymotion';
    status: 'ready' | 'inprogress' | 'error';
    poster: string;
    path: string | { [key: string]: string };
}

export interface VideoExternal extends BaseVideoProps {
    type: 'youtube' | 'vimeo' | 'dailymotion';
    path: string;
}

export interface VideoAsset extends BaseVideoProps {
    type: 'asset';
    path: { [key: string]: string };
}

interface Props {
    name: string;
    className?: string;
    autoplay?: boolean;
    controls?: boolean;
    data: VideoExternal | VideoAsset;
}

const renderVideo = ({ data, ...props }: Props) => {
    switch (data.type) {
        case 'youtube':
            return <PimcoreVideoYoutube {...props} src={data.path} />;
        case 'vimeo':
            return <PimcoreVideoVimeo {...props} src={data.path} />;
        case 'dailymotion':
            return <Alert variant="warning">Dailymotion not supported</Alert>;
        case 'asset':
            return <PimcoreVideoAsset {...props} src={data.path} poster={data.poster} />;
        default:
            return null;
    }
};

const renderTwig = ({ name, data, ...props }: Props) => (
    <HtmlHolder html={`{{ pimcore_video("${name}", ${JSON.stringify(props)}) }}`} />
);

const PimcoreVideo = ({ className, data, ...props }: Props) => {
    if (process.env.RENDER_MODE === 'twig') {
        return renderTwig({ data, ...props });
    }
    return <div className={`${className} pimcore-video`}>{renderVideo({ data, ...props })}</div>;
};

export default PimcoreVideo;
