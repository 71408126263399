import React, { ReactNode } from 'react';
import cx from 'classnames';

import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import useStores from '../../store/use-stores';
import sleep from '../../utils/sleep';
import ContentPaneHeader from '../ContentPaneHeader';

interface Props {
    children: ReactNode;
}

const ContentPane = observer(({ children }: Props) => {
    const { AppStore } = useStores();
    const history = useHistory();
    const classes = cx(['contentPane', AppStore.showContent ? 'active' : 'inactive']);
    const returnHome = async () => {
        AppStore.setShowContent(false);
        await sleep(600);
        history.push('/');
    };
    return (
        <React.Fragment>
            <div
                className={cx(['backdrop', AppStore.showContent ? 'bdActive' : 'bdInactive'])}
                onClick={returnHome}
            >
                &nbsp;
            </div>
            <div className={classes}>
                <ContentPaneHeader />
                <div className="content">{children}</div>
            </div>
        </React.Fragment>
    );
});

export default ContentPane;
