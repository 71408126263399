import React, { Fragment } from 'react';
import Alert from 'react-bootstrap/Alert';
import useStores from '../../store/use-stores';
import BrickRegistry from '../../utils/brick-registry';
import HtmlHolder from '../HtmlHolder';

type Group = { [key: string]: string[] };
type GlobalParams = { [key: string]: string | number | boolean };
type Params = { [key: string]: GlobalParams };
type ImageData = {
    src: string;
    alt: string;
};
type NestedData = {
    key: string;
    name: string;
    type: string;
    reactComponent: string;
    data: string | ImageData | NestedData[];
};
type Indices = Array<{ key: string; type: string; hidden: boolean }>;

interface Props {
    name: string;
    allowed?: string[];
    group?: Group;
    globalParams?: GlobalParams;
    params?: Params;
    manual?: boolean;
    reload?: boolean;
    dontCheckEnabled?: boolean;
    limit?: number;
    data?: { [key: string]: NestedData };
}

const buildOptions = ({ name, data, ...props }: Props) => JSON.stringify(props, null, 2);

const renderTwig = ({ ...props }: Props) => (
    <HtmlHolder html={`{{ pimcore_areablock("${props.name}", ${buildOptions(props)}) }}`} />
);

const renderReact = (indices: Indices, blockData: { [key: string]: NestedData }) => {
    return (
        <Fragment>
            {indices.map((i) => {
                const Component = BrickRegistry.get(i.type);
                if (Component) {
                    return <Component key={i.key} index={i.key} data={blockData} />;
                }
                return (
                    <Alert variant="danger" key={i.key}>
                        <Alert.Heading>Error</Alert.Heading>
                        Area brick of type <strong>{i.type}</strong> not found in the brick
                        registry.
                    </Alert>
                );
            })}
        </Fragment>
    );
};

const PimcoreAreablock = ({ ...props }: Props) => {
    const { PageStore } = useStores();
    if (process.env.RENDER_MODE === 'twig') {
        return renderTwig(props);
    }

    const blockData = PageStore.elements[`${props.name}`];
    if (blockData && blockData.type === 'areablock' && 'indices' in blockData) {
        return renderReact(blockData.indices, blockData.data);
    }
    return null;
};

export default PimcoreAreablock;
