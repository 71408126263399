import React from 'react';
import 'videojs-youtube';
import VideoPlayer from '../VideoPlayer';

export interface VideoYoutubeProps {
    src: string;
    controls?: boolean;
    autoplay?: boolean;
}

const PimcoreVideoYoutube = ({ src, controls, autoplay }: VideoYoutubeProps) => {
    return (
        <VideoPlayer
            controls={controls}
            autoplay={autoplay}
            sources={[{ type: 'video/youtube', src }]}
        />
    );
};

export default PimcoreVideoYoutube;
