import React, { ReactNode } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

interface Props {
    children: ReactNode;
}

const Grid = ({ children }: Props) => (
    <Container fluid className={'grid'}>
        <Row xs={1} sm={2} md={2} lg={3} xl={4} className={'gridRow'}>
            {children}
        </Row>
    </Container>
);

export default Grid;
