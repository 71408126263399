import React from 'react';
import VideoPlayer from '../VideoPlayer';
import '@devmobiliza/videojs-vimeo/dist/videojs-vimeo.cjs';

export interface VideoVimeoProps {
    src: string;
    controls?: boolean;
    autoplay?: boolean;
}

const PimcoreVideoVimeo = ({ src, controls, autoplay }: VideoVimeoProps) => {
    return (
        <VideoPlayer
            controls={controls}
            autoplay={autoplay}
            sources={[{ type: 'video/vimeo', src }]}
        />
    );
};
export default PimcoreVideoVimeo;
