import { observer } from 'mobx-react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Locale } from '../../store/app/types';
import useStores from '../../store/use-stores';

const Home = observer(() => {
    const { t } = useTranslation();
    const { AppStore } = useStores();

    const handleLocaleChange = useCallback(
        (e: React.FormEvent<HTMLButtonElement>) => {
            AppStore.setLocale(e.currentTarget.value as Locale);
        },
        [AppStore]
    );
    AppStore.setShowContent(false);

    return null;
});

export default Home;
