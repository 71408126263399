import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import cx from 'classnames';
import { observer } from 'mobx-react';

import { Link } from 'react-router-dom';

interface Props {
    image: string;
    title: string;
    boldTitle: string;
    path: string;
}

const Cell = observer(({ image, title, boldTitle, path }: Props) => {
    const [mouseOver, setMouseOver] = useState(false);
    const handleMouseIn = () => {
        setMouseOver(true);
    };
    const handleMouseOut = () => {
        setMouseOver(false);
    };

    const classNames = cx(['rollover', mouseOver ? 'active' : null]);
    return (
        <Col
            className={cx(['cell', 'square'])}
            onMouseEnter={handleMouseIn}
            onMouseLeave={handleMouseOut}
        >
            <img src={image} alt={title} className={'image'} />
            <Link to={path} className={classNames}>
                <h3>
                    {title}
                    <br />
                    <strong>{boldTitle}</strong>
                </h3>
            </Link>
        </Col>
    );
});

export default Cell;
