import { observable, toJS } from 'mobx';
import { RootStoreInitialState } from './root-store-initial-state-interface';
import { AppStore } from './app';
import { NavStore } from './nav';
import PageStore from './page';
import { GridStore } from './grid';

export interface Stores {
    AppStore: AppStore;
    NavStore: NavStore;
    PageStore: PageStore;
    GridStore: GridStore;
}

export default class RootStore {
    @observable public stores: Stores;

    constructor(initialState?: RootStoreInitialState) {
        this.stores = {
            AppStore: new AppStore(),
            NavStore: new NavStore(),
            PageStore: new PageStore(),
            GridStore: new GridStore(),
        };

        if (initialState) {
            this.hydrateStores(initialState);
        }
    }

    public hydrateStores(state: RootStoreInitialState) {
        this.stores.AppStore.setInitialState(state.AppStore);
        this.stores.NavStore.setInitialState(state.NavStore);
        this.stores.PageStore.setInitialState(state.PageStore);
        this.stores.GridStore.setInitialState(state.GridStore);
    }

    public getState() {
        return toJS(this.stores, { recurseEverything: false, detectCycles: true });
    }
}
