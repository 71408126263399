import React from 'react';
import PimcoreImage from 'components/PimcoreImage';

interface Props {
    index: string;
    data: Record<
        string,
        {
            key: string;
            name: string;
            type: string;
            reactComponent: string;
            data: { src: string; alt: string }[];
        }
    >;
}

const GalleryAreaBrick = ({ index, data }: Props) => {
    return (
        <div className="row">
            {data &&
                data[`${index}.gallery_block`].data.map((d, x) => (
                    <div key={x} className="col-sm-4 col-xs-6">
                        <PimcoreImage name="image" data={d} />
                    </div>
                ))}
        </div>
    );
};

export default GalleryAreaBrick;
