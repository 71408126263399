import { observable, action } from 'mobx';
import Axios from 'axios';
import { BaseStore } from '../base-store';
import PimcoreInput from '../../components/PimcoreInput/PimcoreInput.interface';
import PimcoreAreablock from '../../components/PimcoreAreablock/PimcoreAreablock.interface';
import PimcoreImage from '../../components/PimcoreImage/PimcoreImage.interface';
import PimcoreBlock from '../../components/PimcoreBlock/PimcoreBlock.interface';

interface Page {
    id: number;
    title: string;
    description: string;
    elements: { [key: string]: Element };
}

type Element = PimcoreInput | PimcoreAreablock | PimcoreImage | PimcoreBlock;
export default class PageStore extends BaseStore<PageStore> {
    @observable id: number = 0;
    @observable title: string = '';
    @observable description: string = '';
    @observable elements: { [key: string]: Element } = {};
    @observable hasError: boolean = false;
    @observable isFetching: boolean = false;

    @action fetchPage = async (path: string) => {
        this.setFetching(true);
        this.id = 0;
        this.title = '';
        this.description = '';
        this.elements = {};
        this.hasError = false;
        try {
            const res = await Axios.get<Page>(`/${path}`);
            // if (res && res.data) {
            this.setPage(res.data);
            // }
            this.setFetching(false);
            this.setError(false);
        } catch (err) {
            console.error(err);
            this.setError(true);
            this.setFetching(false);
        }
    };

    @action setPage = (page: Page) => {
        this.id = page.id;
        this.title = page.title;
        this.description = page.description;
        this.elements = page.elements;
    };

    @action setError(val: boolean) {
        this.hasError = val;
    }

    @action setFetching(val: boolean) {
        this.isFetching = val;
    }
}
