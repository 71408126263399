import React from 'react';

interface Props {
    html: string;
}

const HtmlHolder = ({ html }: Props) => {
    return (
        <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: `</div>${html}<div data-type="html-holder">` }}
            data-type="html-holder"
        />
    );
};

export default HtmlHolder;
