import { observable, action } from 'mobx';
import { BaseStore } from '../base-store';
import { Locale } from './types';
// import { ContactFormData } from '../../api/interfaces/contact-form-data.interface';
// import { postContactForm } from '../../api/postContactForm';

export class AppStore extends BaseStore<AppStore> {
    @observable locale: Locale = 'nl_NL';
    @observable hasError: boolean = false;
    @observable errorMessage: string = '';
    @observable windowHeight: number = -1;
    @observable contentHeight: number = -1;
    @observable showContent: boolean = false;
    @observable contentSizeX: number = 6;
    @observable contentBackgroundColor: string = '#ebebeb';
    @observable showContactContent: boolean = false;
    @observable affixRef: string | null = null;
    @observable contactFormSuccess: boolean = false;
    @observable contactFormError: boolean = false;
    @observable renderTwig: boolean = false;

    @action
    setError(msg: string) {
        this.hasError = true;
        this.errorMessage = msg;
    }

    @action
    clearError() {
        this.hasError = false;
        this.errorMessage = '';
    }

    @action setLocale(locale: Locale) {
        this.locale = locale;
    }

    @action setWindowHeight(val: number) {
        this.windowHeight = val;
    }

    @action setContentHeight(val: number) {
        this.contentHeight = val;
    }

    @action setShowContent(val: boolean) {
        this.showContent = val;
    }

    @action setContentSizeX(val: number) {
        this.contentSizeX = val;
    }

    @action setContentBackgroundColor(val: string) {
        this.contentBackgroundColor = val;
    }

    @action setAffixRef(val: null | string) {
        this.affixRef = val;
    }

    @action setShowContactContent(val: boolean) {
        this.showContactContent = val;
    }

    @action setContactFormSuccess(val: boolean) {
        this.contactFormSuccess = val;
    }
    @action setContactFormError(val: boolean) {
        this.contactFormError = val;
    }

    @action setRenderTwig(val: boolean) {
        this.renderTwig = val;
    }

    // @action async postContactForm(data: ContactFormData) {
    //     try {
    //         const result = await postContactForm(data);
    //         if (result.data.success) {
    //             this.clearError();
    //             this.setContactFormError(false);
    //             this.setContactFormSuccess(true);
    //         } else {
    //             this.setContactFormError(true);
    //             this.setContactFormSuccess(false);
    //         }
    //     } catch (err) {
    //         this.setError('Er was een probleem bij het verzenden van het contactformulier');
    //         this.setContactFormError(true);
    //         this.setContactFormSuccess(false);
    //     }
    // }
}
