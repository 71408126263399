import React from 'react';
import PimcoreImage from '../../PimcoreImage';

interface Props {
    index: string;
    data: {
        [key: string]: {
            key: string;
            name: string;
            type: string;
            reactComponent: string;
            data: {
                src: string;
                alt: string;
            };
        };
    };
}

const ImageAreabrick = ({ index, data }: Props) => {
    return <PimcoreImage name="image" data={data && data[`${index}.image`].data} />;
};

export default ImageAreabrick;
