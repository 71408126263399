import React, { Component, ErrorInfo, Fragment } from 'react';

export default class ErrorBoundary extends Component<
    {},
    { hasError: boolean; error?: Error; errorInfo?: ErrorInfo }
> {
    static getDerivedStateFromError(_error: Error) {
        return { hasError: true, error: _error };
    }

    constructor(props: {}) {
        super(props);
        this.state = { hasError: false, error: undefined, errorInfo: undefined };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error(error, errorInfo);
        this.setState({ error, errorInfo });
    }

    render() {
        if (this.state.hasError) {
            return (
                <Fragment>
                    <h1>An error has occurred</h1>
                </Fragment>
            );
        }

        return this.props.children;
    }
}
