import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { ReactNode, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import Grid from '../../components/Grid';
import Cell from '../../components/Grid/Cell';
import Sidebar from '../../components/Sidebar';
import { FetchStatus } from '../../store/grid';
import useStores from '../../store/use-stores';

interface Props {
    children: ReactNode;
}

const DefaultLayout = observer(({ children }: Props) => {
    const { GridStore } = useStores();
    const { fetchGridCells } = GridStore;
    useEffect(() => {
        fetchGridCells();
    }, [fetchGridCells]);
    const renderCells = () => {
        if (GridStore.status === FetchStatus.SUCCESS) {
            return GridStore.gridCells.map((g) => {
                return (
                    <Cell
                        key={g.id}
                        image={g.image}
                        path={`${g.path}`}
                        title=""
                        boldTitle={g.title}
                    />
                );
            });
        }
    };
    return (
        <Container fluid className={cx(['responsiveHeight', 'all'])} style={{ padding: 0 }}>
            <Row noGutters className={'responsiveHeight'}>
                <Sidebar />

                <Col className={'main'}>
                    <Grid>{renderCells()}</Grid>
                    {children}
                </Col>
            </Row>
        </Container>
    );
});

export default DefaultLayout;
