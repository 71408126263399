import React, { useEffect, Fragment } from 'react';
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet-async';
import { observer } from 'mobx-react';
import useStores from '../../store/use-stores';
import ContentPane from '../../components/ContentPane';
import PimcoreAreablock from '../../components/PimcoreAreablock';

const Page = observer(() => {
    const { AppStore, PageStore } = useStores();
    const { path } = useParams();

    // const fes, AppStore, PageStore]);

    useEffect(() => {
        // async function fetchCon
        PageStore.fetchPage(path!);
        AppStore.setShowContent(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path, AppStore, PageStore]);

    const renderLoaded = () => {
        if (!PageStore.isFetching && !PageStore.hasError) {
            return (
                <Fragment>
                    <Helmet
                        title={PageStore.title}
                        meta={[{ name: 'description', content: PageStore.description }]}
                    />

                    <PimcoreAreablock name="pageContentArea" />
                </Fragment>
            );
        }
        return null;
    };
    return <ContentPane>{renderLoaded()}</ContentPane>;
});

export default Page;
