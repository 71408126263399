import React from 'react';
import { observer } from 'mobx-react';
import useStores from '../../store/use-stores';
import ContentPane from '../../components/ContentPane';

interface Props {
    message: string;
}

const Error = observer(({ message }: Props) => {
    const { AppStore } = useStores();
    AppStore.setShowContent(true);

    return (
        <ContentPane>
            <h1>Oops!</h1>
            <p>{message}</p>
        </ContentPane>
    );
});

export default Error;
