import React from 'react';
import PimcoreInput from '../../PimcoreInput';
import PimcoreWysiwyg from '../../PimcoreWysiwyg';

type BrickData = {
    key: string;
    name: string;
    type: string;
    reactComponent: string;
    data: string;
};

interface Props {
    index: string;
    data: { [key: string]: BrickData };
}
const WysiwygAreabrick = ({ index, data }: Props) => {
    return (
        <div>
            <h2>
                <PimcoreInput name="blockTitle" data={data && data[`${index}.blockTitle`]?.data} />
            </h2>
            <PimcoreWysiwyg
                name="blockContent"
                data={data && data[`${index}.blockContent`]?.data}
            />
        </div>
    );
};

export default WysiwygAreabrick;
