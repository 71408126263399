import Axios from 'axios';
import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Image from 'react-bootstrap/Image';

import HtmlHolder from '../HtmlHolder';

interface Props {
    name: string;
    required?: boolean;
    thumbnail?: string;
    className?: string;
    data?: {
        src: string;
        alt: string;
    };
}

const buildOptions = ({ name, data, ...props }: Props) => JSON.stringify(props, null, 2);

const renderTwig = ({ ...props }: Props) => (
    <HtmlHolder html={`{{ pimcore_image("${props.name}", ${buildOptions(props)}) }}`} />
);

const renderReact = ({ ...props }: Props) => {
    if (props.data) {
        return (
            <Image
                fluid
                className={props.className}
                src={`${Axios.defaults.baseURL}${props.data.src}`}
                alt={props.data.alt}
            />
        );
    }
    return <Alert variant="warning">The image source was not set</Alert>;
};

const PimcoreImage = ({ ...props }: Props) => {
    if (process.env.RENDER_MODE === 'twig') {
        return renderTwig(props);
    }
    return renderReact(props);
};

export default PimcoreImage;
