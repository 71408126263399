import React from 'react';

import VideoPlayer from '../VideoPlayer';

export interface VideoAssetProps {
    src: { [key: string]: string };
    poster?: string;
    controls?: boolean;
    autoplay?: boolean;
}
const baseUrl = process.env.API_URL || 'https://chantal.hosting.combat.nl';

const PimcoreVideoAsset = ({ src, controls, autoplay, poster }: VideoAssetProps) => {
    const sources = [];
    for (const type in src) {
        sources.push({ type: `video/${type}`, src: `${baseUrl}${src[`${type}`]}` });
    }

    return (
        <VideoPlayer
            poster={`${baseUrl}${poster}`}
            controls={controls}
            autoplay={autoplay}
            sources={sources}
        />
    );
};

export default PimcoreVideoAsset;
