import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import { ReactComponent as Logo } from '../../assets/Logo.svg';
import useStores from '../../store/use-stores';
import NavItem from '../NavItem';

const Sidebar = observer(() => {
    const { NavStore } = useStores();
    return (
        <Navbar className={classNames(['inner', 'responsiveFlexColumn'])} bg="light" expand="md">
            <Navbar.Brand>
                <Logo className={'logo'} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="side-nav" />
            <Navbar.Collapse id="side-nav">
                <Nav
                    defaultActiveKey="/"
                    className={classNames(['responsiveFlexColumn', 'navStyle'])}
                >
                    {NavStore.routes.map((r) => (
                        <NavItem
                            key={r.id}
                            title={r.navigationProperties.title || ''}
                            text={r.navigationProperties.name}
                            to={r.path === '/' ? '/' : `${r.path}`}
                        />
                    ))}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
});

export default Sidebar;
