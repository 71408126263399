import './global.scss';
import 'video.js/dist/video-js.min.css';

import { observer } from 'mobx-react';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Switch } from 'react-router-dom';

import appleTouchIcon from './assets/apple-touch-icon.png';
import favicon16 from './assets/favicon-16x16.png';
import favicon32 from './assets/favicon-32x32.png';
import safariTab from './assets/safari-pinned-tab.svg';
import BlockRepeatAreabrick from './components/bricks/block-repeat-areabrick';
import GalleryAreaBrick from './components/bricks/gallery-areabrick';
import ImageAreabrick from './components/bricks/image-areabrick';
import VideoAreabrick from './components/bricks/video-areabrick';
import WysiwygAreabrick from './components/bricks/wysiwyg-areabrick';
import ErrorBoundary from './components/ErrorBoundary';
import DefaultLayout from './layouts/DefaultLayout';
import Error from './Pages/Error';
import Home from './Pages/Home';
import Page from './Pages/Page';
import routes from './routes';
import useStores from './store/use-stores';
import BrickRegistry from './utils/brick-registry';

const defaultTitle = 'Hair extensions by Chantal';
BrickRegistry.register({ name: 'wysiwyg', component: WysiwygAreabrick });
BrickRegistry.register({ name: 'block-repeat', component: BlockRepeatAreabrick });
BrickRegistry.register({ name: 'image', component: ImageAreabrick });
BrickRegistry.register({ name: 'video', component: VideoAreabrick });
BrickRegistry.register({ name: 'gallery', component: GalleryAreaBrick });

const App: React.FC = observer(() => {
    const { AppStore } = useStores();

    return (
        <ErrorBoundary>
            <DefaultLayout>
                <Helmet
                    defaultTitle={defaultTitle}
                    titleTemplate={`%s - ${defaultTitle}`}
                    link={[
                        { rel: 'apple-touch-icon', sizes: '180x180', href: appleTouchIcon },
                        { rel: 'icon', type: 'image/png', sizes: '32x32', href: favicon32 },
                        { rel: 'icon', type: 'image/png', sizes: '16x16', href: favicon16 },
                        { rel: 'manifest', href: '/static/assets/site.webmanifest' },
                        { rel: 'mask-icon', href: safariTab, color: '#5bbad5' },
                    ]}
                    meta={[{ name: 'msapplication-TileColor', content: '#da532c' }]}
                />
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path={routes.page} exact component={Page} />
                    <Route
                        render={() => {
                            AppStore.setShowContent(false);
                            return <Error message="404!" />;
                        }}
                    />
                </Switch>
            </DefaultLayout>
        </ErrorBoundary>
    );
});

export default App;
