import React, { Fragment } from 'react';
import HtmlHolder from '../HtmlHolder';

interface Props {
    name: string;
    width?: number;
    htmlspecialchars?: boolean;
    nowrap?: boolean;
    class?: string;
    placeholder?: string;
    required?: boolean;
    data?: string;
}

const buildOptions = ({ name, data, ...props }: Props) => {
    return JSON.stringify(props, null, 2);
};

const renderTwig = ({ ...props }: Props) => {
    return <HtmlHolder html={`{{ pimcore_input("${props.name}", ${buildOptions(props)}) }}`} />;
};

const renderReact = ({ ...props }: Props) => <Fragment>{props.data || ''}</Fragment>;

const PimcoreInput = ({ ...props }: Props) => {
    if (process.env.RENDER_MODE === 'twig') {
        return renderTwig(props);
    }
    return renderReact(props);
};

export default PimcoreInput;
