import { observable, action } from 'mobx';
import Axios from 'axios';
import { BaseStore } from '../base-store';
import { RouteDto } from '../../dto/route.dto';
import { RouteResponseDto } from '../../dto/route-response.dto';

export class NavStore extends BaseStore<NavStore> {
    @observable routes: RouteDto[] = [];

    @action setRoutes = (routes: RouteDto[]) => {
        this.routes = routes;
    };

    @action fetchRoutes = async () => {
        console.warn('FTEHC ROUTES');
        try {
            const response = await Axios.get<RouteResponseDto>('/combat-react/api/routes');
            this.setRoutes(response.data.routes);
            console.log('FETCHED ROUTES');
        } catch (err) {
            console.error(err);
        }
    };
}
