const sleep = (ms: number) => {
    return new Promise((resolve, _reject) => {
        setTimeout(() => {
            console.log(`Slept for: ${ms}ms`);
            resolve();
        }, ms);
    });
};

export default sleep;
