import React from 'react';
import PimcoreVideo, { VideoAsset, VideoExternal } from '../../PimcoreVideo';

interface BrickData {
    key: string;
    name: string;
    type: 'video';
    reactComponent: 'PimcoreVideo';
    data: VideoAsset | VideoExternal;
}

interface Props {
    index: string;
    data: { [key: string]: BrickData };
}

const VideoAreabrick = ({ index, data }: Props) => {
    return (
        <div className={'video'}>
            <PimcoreVideo
                name="video"
                data={data && data[`${index}.video`].data}
                autoplay={false}
                controls={true}
            />
        </div>
    );
};

export default VideoAreabrick;
