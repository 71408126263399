import React, { useEffect, ReactNode, memo } from 'react';
import i18next from 'i18next';
import i18nextXHRBackend from 'i18next-xhr-backend';
import { I18nextProvider } from 'react-i18next';
import { observer } from 'mobx-react';

import useStores from '../store/use-stores';
import enUS from './locales/en_US/translation.json';
import nlNL from './locales/nl_NL/translation.json';

if (__BROWSER__) {
    i18next.use(i18nextXHRBackend);
}

i18next.init({
    backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    react: {
        useSuspense: false,
        wait: true,
    },
    debug: process.env.NODE_ENV === 'development' && __BROWSER__,
    fallbackLng: 'en_US',
    fallbackNS: 'translation',
    partialBundledLanguages: true,
    resources: {
        en_US: { translation: enUS },
        nl_NL: { translation: nlNL },
    },
    parseMissingKeyHandler: (missing) => {
        if (process.env.NODE_ENV === 'development' && __BROWSER__) {
            console.warn(`MISSING TRANSLATION: ${missing}`);
        }
        return missing;
    },
});

i18next.languages = ['en_US', 'nl_NL'];

const I18N: React.FC<{ children: ReactNode }> = observer(({ children }) => {
    const { AppStore } = useStores();
    const locale = AppStore.locale;

    useEffect(() => {
        i18next.changeLanguage(locale);
    }, [locale]);
    return <I18nextProvider i18n={i18next}>{children}</I18nextProvider>;
});

export default memo(I18N);
