import React, { Fragment, FunctionComponent } from 'react';
import HtmlHolder from '../HtmlHolder';

export interface BlockTemplateProps<T = { [key: string]: any }> {
    data: T;
}
type ImageData = {
    src: string;
    alt: string;
};
type NestedData = {
    key: string;
    name: string;
    type: string;
    reactComponent: string;
    data: string | ImageData | { [key: string]: NestedData };
};

export type TemplateComponent = FunctionComponent<{
    key: string;
    index: string;
    data?: { [key: string]: NestedData };
}>;
interface Props {
    name: string;
    indices?: string[];
    data?: { [key: string]: NestedData };
    limit?: number;
    default?: number;
    manual?: boolean;
    class?: string;
    template: TemplateComponent;
}

const buildOptions = ({ name, template, data, ...props }: Props) => JSON.stringify(props, null, 2);

const renderTwig = (Component: TemplateComponent, { ...props }: Props) => (
    <Fragment>
        <HtmlHolder
            html={`{% for i in pimcore_iterate_block(pimcore_block("${props.name}", ${buildOptions(
                props
            )})) %}`}
        />
        <Component index="1" key="1" />
        <HtmlHolder html={'{% endfor %}'} />
    </Fragment>
);

const renderReact = (
    indices: string[],
    blockData: { [key: string]: NestedData },
    Component: TemplateComponent
) => {
    return (
        <Fragment>
            {indices.map((i) => {
                console.log(i);
                return <Component key={i} index={i} data={blockData} />;
            })}
        </Fragment>
    );
};

const PimcoreBlock: FunctionComponent<Props> = ({ ...props }: Props) => {
    if (process.env.RENDER_MODE === 'twig') {
        return renderTwig(props.template, props);
    }
    // console.log(props);

    if (props.data && 'indices' in props) {
        return renderReact(props.indices || [], props.data, props.template);
    }

    return null;
};

export default PimcoreBlock;
