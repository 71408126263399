import React, { Fragment } from 'react';
import PimcoreInput from '../../PimcoreInput';
import PimcoreWysiwyg from '../../PimcoreWysiwyg';
import PimcoreBlock, { TemplateComponent } from '../../PimcoreBlock';

interface NestedElement {
    key: string;
    name: string;
    type: string;
    reactComponent: string;
    data: string;
}

export type BrickData = {
    key: string;
    name: string;
    type: string;
    reactComponent: string;
    data: { [key: string]: NestedElement };
    indices: string[];
};

interface Props {
    index: string;
    data?: { [key: string]: BrickData };
}

const Template: TemplateComponent = (props) => {
    return (
        <Fragment>
            <h2>
                <PimcoreInput
                    name="subTitle"
                    data={props.data && props.data[`${props.index}.subTitle`].data.toString()}
                />
            </h2>
            <PimcoreWysiwyg
                name="content"
                data={props.data && props.data[`${props.index}.content`].data.toString()}
            />
        </Fragment>
    );
};

const BlockRepeatAreabrick = ({ ...props }: Props) => {
    return (
        <PimcoreBlock
            name="contentBlock"
            template={Template}
            data={props.data && props.data[`${props.index}.contentBlock`].data}
            indices={props.data && props.data[`${props.index}.contentBlock`].indices}
        />
    );
};

export default BlockRepeatAreabrick;
