import React, { SyntheticEvent } from 'react';
import Nav from 'react-bootstrap/Nav';
import { LinkContainer } from 'react-router-bootstrap';

import { useHistory } from 'react-router';
import { observer } from 'mobx-react';
import useStores from '../../store/use-stores';
import sleep from '../../utils/sleep';

interface Props {
    title: string;
    text: string;
    to: string;
}

const NavItem = observer(({ title, text, to }: Props) => {
    const { AppStore } = useStores();
    const history = useHistory();

    const handleClick = async (e: SyntheticEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        const href = e.currentTarget.pathname;
        if (href !== history.location.pathname) {
            if (AppStore.showContent) {
                AppStore.setShowContent(false);
                await sleep(300);
            }
            history.push(href);
        }
    };
    return (
        <LinkContainer exact to={to} activeClassName={'active'} onClick={handleClick}>
            <Nav.Link title={title} className={'link'}>
                {text}
            </Nav.Link>
        </LinkContainer>
    );
});

export default NavItem;
