import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router';
import useStores from '../../store/use-stores';
import PimcoreInput from '../PimcoreInput';

import ContentPaneImage from '../ContentPaneImage';
import sleep from '../../utils/sleep';

const ContentPaneHeader = observer(() => {
    const { AppStore, PageStore } = useStores();
    const history = useHistory();

    const handleClose = async () => {
        AppStore.setShowContent(false);
        await sleep(600);
        history.push('/');
    };

    return (
        <Fragment>
            <div className={'contentPaneHeader'}>
                <h1 className={'pageTitle'}>
                    <span className={'pageTitleLight'}>
                        <PimcoreInput
                            name="pageTitleLight"
                            placeholder="lichte titel"
                            required={false}
                            data={PageStore.elements.pageTitleLight?.data.toString()}
                        />
                    </span>{' '}
                    <PimcoreInput
                        name="pageTitle"
                        placeholder="Title of the page"
                        required={true}
                        data={PageStore.elements.pageTitle?.data.toString()}
                    />
                </h1>
                <Button className={'closeButton'} onClick={handleClose}>
                    &times;
                </Button>
            </div>
            <ContentPaneImage />
        </Fragment>
    );
});

export default ContentPaneHeader;
