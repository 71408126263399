import { createBrowserHistory, createMemoryHistory } from 'history';

type HistoryParams = {
    initialEntries?: string[];
};

export const createHistory = (params?: HistoryParams) => {
    if (__BROWSER__) {
        const history = window.browserHistory || createBrowserHistory();
        if (process.env.NODE_ENV === 'development' && !window.browserHistory) {
            window.browserHistory = history;
        }
        return history;
    }
    return createMemoryHistory({ initialEntries: params?.initialEntries });
};

export default createHistory;
